import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(isToday)
dayjs.extend(isYesterday)

export function SubscriptionPriceFilter(s){
  if(s === 0) return 'Free'
  else if (typeof s === 'string') return s
  return `$${s}/m`
}

export function OneIndexFilter(s){
  return parseInt(s) >= 0 ? `${parseInt(s) + 1}` : '--'
}

export function LetterFilter(s){
  return parseInt(s) >= 0 ? `${String.fromCharCode( 65 + parseInt(s))}` : '--'
}


export function CurrencyFilter(s) {
    if (s) {
      return `${(Number(s)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} kr.`
    } else if (s instanceof Number) {
      return '0 kr';
    }
    return '---';
  }

  export function DateFilter(s) {
    if (!s) return '';
    
    const date = dayjs(s);
    const now = dayjs();
    
    // If it's a different year, show the year
    if (date.year() !== now.year()) {
      return date.format('D MMM YYYY HH:mm');
    }
    
    // Today
    if (date.isToday()) {
      return `Today at ${date.format('HH:mm')}`;
    }
    
    // Yesterday
    if (date.isYesterday()) {
      return `Yesterday at ${date.format('HH:mm')}`;
    }
    
    // Within the last 7 days, show the weekday
    if (now.diff(date, 'day') < 7) {
      return date.format('dddd [at] HH:mm');
    }
    
    // Default format for dates in the current year
    return date.format('D MMM HH:mm');
  }

  export function PointsFilter(s) {
    return s ? `${s} points` : '-- points'
  }
